/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:00379d28-d4eb-4a5f-b92d-75e088c6496a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xRRwlJhDE",
    "aws_user_pools_web_client_id": "4hcepuau1ti5q7s3vb14gievva",
    "oauth": {}
};


export default awsmobile;
